import { Outlet } from "react-router-dom"
import Header from "./Header"
import Sidebar from "./Sidebar"

import InternetConnection from "../components/InternetConnection"


export default () => {

    return (
        <InternetConnection >
            <Sidebar />
            <Header />

            <section className="pc-container">
                <div className="pc-content">
                    <Outlet />
                </div>
            </section>
        </InternetConnection>
    )
}