import { lazy } from "react"
import Loadable from '../components/Loadable';

const page = (page) =>
    Loadable(
        lazy(
            () => import(`../pages/${page}`)
        )
    )



/****************** Common Pages ******************************/
export const Setting = page("Setting")
export const Patients = page("Patients")
export const PatientProfile = page("PatientProfile")
export const Payments = page("Payments")
export const Appointments = page("Appointments")
export const PaymentReceipt = page("Templates/PaymentReceipt")
export const Sample = page("Sample")
export const Homepage = page('Homepage')
export const APITesting = page('APITesting')
export const AppointmentCard = page('Templates/AppointmentCard')
export const PrintAppointment = page('PrintAppointment')

/************************************************************ */

/**************** Reception Pages *************************** */
export const RC_Dashboard = page("Reception/Dashboard")
export const RC_Reports = page("Reception/Reports")
export const RC_NextVisit = page("Reception/NextVisit")
export const RC_Addmissions = page("Reception/Addmissions")
/************************************************************ */


/****************** Doctors Pages ******************************/
export const DR_Dashboard = page("Doctor/Dashboard")
export const DR_CurrentTokens = page("Doctor/PatientsReview")
export const DR_Perscription = page('Doctor/Perscription')
export const DR_Disease = page('Doctor/AddDiseases')
export const DR_ChiefOfComplaints = page('Doctor/ChiefOFComplaint')

/**************************************************************/


/****************** Pharmacist Pages ******************************/
export const PH_Dashboard = page("Pharmacist/Dashboard")
export const PH_AddMedicine = page("Pharmacist/AddMedicine")
export const PH_Medicines = page("Pharmacist/Medicines")
export const PH_GenerateInvoice = page("Pharmacist/GenerateInvoice")
export const PH_Invoice = page("Templates/MedicineInvoice")
/**************************************************************/

/******************************Super Admin********************************/
export const SA_Dashboard = page('SuperAdmin/Dashboard')
export const SA_Users = page('SuperAdmin/Users')
export const SA_Services = page('SuperAdmin/Services')
export const SA_Expense = page('SuperAdmin/Expense')
export const SA_Income = page('SuperAdmin/Income')
export const SA_Rooms = page('SuperAdmin/Rooms')
/**************************************************************/


/******************************Accountant********************************/

export const AC_DashBoard= page("Accountant/DashBoard")
export const AC_Earning= page("Accountant/Earning")
export const AC_Expenses= page("Accountant/Expenses")
export const AC_Categories= page("Accountant/Categories")
export const AC_Setup= page("Accountant/SetUp")



/**************************************************************/

/****************************** IPD ********************************/
export const IPD_DashBoard= page("Ipd/DashBoard")
export const IPD_Patients= page("Ipd/Patients")
export const IPD_PatientSchedule = page("Ipd/PatientSchedule")
export const IPD_DosingSchedules = page("Ipd/DosingSchedule")





/**************************************************************/




