import React from "react";

const Select = ({ list=[], label,register ={} , margin=true, ...props }) => {
  return (
    <div className={margin ? "mb-3" : ""}>
      {label && <label className="form-label">{label}</label>}
      <select className="form-select" {...register} defaultValue={list[0]} { ...props}>
        {list?.map((value, i)=>(<option className="" value={value.value} key={i}>{value.label}</option>))}
      </select>
    </div>
  );
};

export default Select;
