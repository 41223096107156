import * as constant from '../constant';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/reducers/auth';

import { Link, NavLink, useLocation } from 'react-router-dom';
import { RC_MENU } from '../constant';
import Icon from '../components/Elements/Icon'
import { toggleMobileSidebar } from '../store/reducers/common';

export default () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)
    const { mobileSidebar } = useSelector(state => state.common)
    const location = useLocation()

    return (
        <>
            <nav className={`pc-sidebar bg-white  ${ mobileSidebar && "mob-sidebar-active"}`}>
                <div className="navbar-wrapper d-block">
                    <div className="p-3 text-center bg-light-primary">
                        <Link to={'/'} className='text-dark  font-bold fs-4'>
                            SHUBH SUPERSPECIALITY CENTER
                        </Link>
                    </div>
                    <div className="navbar-content pc-trigger active simplebar-scrollable-y pt-0" >
                        <div className="simplebar-wrapper " style={{ height: "calc(-8rem + 100vh)"}}>
                            <div className="card hover-effect ">
                                <div className="card-body">
                                    <div className="d-flex flex-shrink-0">
                                        <img src={user.image} alt="user-image" className="user-avtar wid-50 hei-50 rounded-circle me-3 object-fit-cover" />
                                        <div className="d-flex align-items-center">
                                            <div className="me-2">
                                                <h5 className="mb-0">{user.name}</h5>
                                                <small>{user.email}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <button className="btn btn-danger mt-3 w-100 rounded py-1" onClick={() => (dispatch(logout()), mobileSidebar && dispatch(toggleMobileSidebar()))}>Logout</button>
                                </div>
                            </div>
                            <ul className="pc-navbar " style={{ display: "block" }}>

                                {RC_MENU[user.role?.role].map((item, i) => (
                                    <li className={`pc-item  ${item.path == location.pathname && "active"}`} key={i} onClick={ () => mobileSidebar && dispatch(toggleMobileSidebar())}>
                                        <NavLink className={`pc-link w-100`} id={item.id} to={item.path}>
                                            <Icon icon={item.icon} className="mx-2" />
                                            {item.name}
                                        </NavLink>

                                    </li>
                                ))}

                            </ul>

                        </div>
                        <div
                            className="simplebar-track simplebar-horizontal"
                            style={{ visibility: "hidden" }}
                        >
                            <div
                                className="simplebar-scrollbar"
                                style={{ width: 0, display: "none" }}
                            />
                        </div>
                        <div
                            className="simplebar-track simplebar-vertical"
                            style={{ visibility: "visible" }}
                        >
                            <div
                                className="simplebar-scrollbar"
                                style={{
                                    height: 48,
                                    transform: "translate3d(0px, 0px, 0px)",
                                    display: "block"
                                }}
                            />
                        </div>
                    </div>
                </div>

            </nav>
            {mobileSidebar && <div className="pc-menu-overlay" onClick={() => dispatch(toggleMobileSidebar())}></div>}
        </>
    )
}